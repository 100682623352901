<template>
  <div>
    <a-card title="审批销售详情">
      <template slot="extra">
        <a-space>
          <a-button type="primary" ghost icon="printer" v-print="'#printContent'"> 打印</a-button>
          <a-button type="primary" ghost icon="left" @click="$router.go(-1)">返回</a-button>
        </a-space>
      </template>

      <section id="printContent">
        <a-spin :spinning="getSalesOrderLoading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="销售单号"> {{ salesOrderItem.number }}-{{ page }} </a-descriptions-item>
            <a-descriptions-item label="制单日期">
              {{ salesOrderItem.handle_time }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ salesOrderItem.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="制单人">
              {{ salesOrderItem.handler_name }}
            </a-descriptions-item>
            <a-descriptions-item label="交货日期">
              {{ salesOrderItem.delivery_date }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ salesOrderItem.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="附件">
              <div v-for="fileItem in salesOrderItem.sales_file_items" :key="fileItem.id">
                <a :href="fileItem.file" target="_blank" style="margin-right: 16px">{{ fileItem.name }}</a>
              </div>
            </a-descriptions-item>

            <!-- <a-descriptions-item label="公司单号">
              {{ salesOrderItem.company_order_number }}
            </a-descriptions-item>
            <a-descriptions-item label="产品型号">
              {{ salesOrderItem.product_model }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
              {{ salesOrderItem.product_name }}
            </a-descriptions-item>
            <a-descriptions-item label="验货日期">
              {{ salesOrderItem.inspection_date }}
            </a-descriptions-item>
            <a-descriptions-item label="合同号">
              {{ salesOrderItem.contract_number }}
            </a-descriptions-item>
            <a-descriptions-item label="客户型号">
              {{ salesOrderItem.client_model }}
            </a-descriptions-item>
            <a-descriptions-item label="参数">
              {{ salesOrderItem.parameter }}
            </a-descriptions-item>
            <a-descriptions-item label="销售国家">
              {{ salesOrderItem.sales_country }}
            </a-descriptions-item>
            <a-descriptions-item label="订单数量">
              {{ salesOrderItem.order_quantity }}
            </a-descriptions-item> -->
          </a-descriptions>

          <!-- <a-divider orientation="left" style="margin-top: 30px">产品信息</a-divider>
          <a-descriptions bordered>
            <a-descriptions-item label="产品编号">
              {{ targetProductItem.goods_number }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
              {{ targetProductItem.goods_name }}
            </a-descriptions-item>
            <a-descriptions-item label="产品条码">
              {{ targetProductItem.goods_barcode }}
            </a-descriptions-item>
            <a-descriptions-item label="产品规格">
              {{ targetProductItem.goods_spec }}
            </a-descriptions-item>
            <a-descriptions-item label="销售数量">
              {{ targetProductItem.sales_quantity }}
            </a-descriptions-item>
          </a-descriptions> -->

          <a-divider orientation="left" style="margin-top: 30px">BOM清单</a-divider>
          <a-table
            rowKey="id"
            size="small"
            :loading="getBOMListLoading"
            :columns="tableColumns"
            :dataSource="targetProductBOMItems"
            :pagination="false"
          />
          <div style="margin-top: 12px; text-align: right">
            <a-pagination :current="page" :total="salesProductItems.length * 10" show-less-items @change="changePage" />
          </div>
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { saleOrderDetail } from "@/api/sale";
import { goodsBOMList } from "@/api/goods";
import JsBarcode from "jsbarcode";

export default {
  data() {
    return {
      salesOrderItem: {},
      salesProductItems: [],

      targetProductItem: {},
      targetProductBOMItems: [],

      page: 1,
      getSalesOrderLoading: false,
      getBOMListLoading: false,
      tableColumns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "产品名称",
          dataIndex: "product_name",
          customRender: (_, item) => item.goods_item.name,
        },
        {
          title: "产品编号",
          dataIndex: "product_number",
          customRender: (_, item) => item.goods_item.number,
        },
        {
          title: "产品条码",
          dataIndex: "product_barcode",
          customRender: (_, item) => item.goods_item.barcode,
        },
        {
          title: "产品规格",
          dataIndex: "product_spec",
          customRender: (_, item) => item.goods_item.spec,
        },
        {
          title: "产品尺寸",
          dataIndex: "product_drawing_number",
          customRender: (_, item) => item.goods_item.drawing_number,
        },
        {
          title: "产品分类",
          dataIndex: "category_name",
          customRender: (_, item) => item.goods_item.category_name,
        },
        {
          title: "数量",
          dataIndex: "quantity",
        },
      ],
    };
  },
  methods: {
    initData() {
      this.getSalesOrderLoading = true;
      saleOrderDetail({ id: this.$route.query.id })
        .then((data) => {
          this.salesOrderItem = data;
          this.salesProductItems = this.salesOrderItem.sales_goods_items;
          this.targetProductItem = this.salesProductItems[0];
          this.getProductBOMList(this.targetProductItem.goods);
          JsBarcode("#barcode", this.salesOrderItem.number, {
            lineColor: "#000",
            width: 2,
            height: 40,
            displayValue: true,
          });
        })
        .finally(() => {
          this.getSalesOrderLoading = false;
        });
    },
    getProductBOMList(id) {
      this.getBOMListLoading = true;
      goodsBOMList({ id })
        .then((data) => {
          this.targetProductBOMItems = data;
        })
        .finally(() => {
          this.getBOMListLoading = false;
        });
    },
    changePage(page) {
      this.page = page;
      this.targetProductItem = this.salesProductItems[page - 1];
      this.getProductBOMList(this.targetProductItem.goods);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
